import React,{Suspense} from 'react';
import Header from './header';
import Menu from './menu';
import Footer from './footer';
import { Routes } from '../routes';
import { Route, Switch} from 'react-router-dom'

const Base = () => {
    return(
        <React.Fragment>
            <Header />
            <Menu />
            <Suspense fallback={<div>Loading</div>}>
                <Switch>
                    {
                        Routes.map((route, index) => {
                            return ((
                                <Route
                                    path={route.path}
                                    key={index}
                                    component={route.component}
                                    exact={route.exact}
                                />
                            ))
                        })
                    }
                </Switch>
            </Suspense>
            <Footer />
        </React.Fragment>
    )
}

export default Base;