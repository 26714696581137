import React, {useEffect} from "react";
import { useLocation } from 'react-router-dom';

const Menu = () => {
    const location = useLocation();
    const currentUrl = location.pathname;

    return (
        <div className="container-fluid p-0">
            <nav className="navbar navbar-expand-lg bg-light navbar-light py-3 py-lg-0 px-lg-5">
                <a href="/" className="navbar-brand ml-lg-3">
                    <img src="/assets/img/logo.jpg" className="img-logo" alt="" />
                </a>
                <button type="button" className="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse justify-content-between px-lg-3" id="navbarCollapse">
                    <div className="navbar-nav m-auto py-0">
                        {currentUrl !== "/admin/contact_leads" && currentUrl !== "/admin/gallery" && currentUrl !== "/admin/couriers" ? (
                            <>
                                <a href="/" className="nav-item nav-link">Home</a>
                                <a href="/services" className="nav-item nav-link">Service</a>
                                <a href="/gallery" className="nav-item nav-link">Gallery</a>
                                <a href="/contact_us" className="nav-item nav-link">Contact</a>
                            </>
                        ) : (
                            <a href="/admin/couriers" style={{fontSize: '25px'}} className="nav-item nav-link">Admin</a>
                        )}
                    </div>

                </div>
            </nav>
        </div>
    )
}

export default Menu;