import React from "react";
import '../Views/custom.css';
const Header = () => {
    return (
        <div className="container-fluid">
            <div className="row py-2 px-lg-5">
                <div className="col-md-12 col-sm-12 col-xs-12 col-lg-12 text-right mb-2 mb-lg-0">
                    <div className="d-inline-flex align-items-center clr-primary header-text">
                        Enquiry / Book now &nbsp;<a className="clr-primary" href="https://wa.me/+96597782015" target="_blank"><i className="fa fa-1x fa-whatsapp mr-2 clr-whatsapp"></i> +965 9778 2015</a>
                        {/*<small className="px-3">|</small>*/}
                        {/*<small><i className="fa fa-user mr-2"></i>Hot Line 24/7</small>*/}
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Header;