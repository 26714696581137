import {lazy} from 'react';

const Home = lazy(() => import("../src/Views/index"));
const Services = lazy(() => import("../src/Views/Services"));
const UIGallery = lazy(() => import("../src/Views/Gallery"));
const Contact = lazy(() => import("../src/Views/Contact"));
const Tracking = lazy(() => import("../src/Views/Tracking"));

// Admin
const Login = lazy(() => import("../src/Views/Admin/Login"));
const Dashboard = lazy(() => import("../src/Views/Admin/Dashboard"));
const ContactLeads = lazy(() => import("../src/Views/Admin/ContactLeads"));
const Gallery = lazy(() => import("../src/Views/Admin/Gallery"));
const Couriers = lazy(() => import("../src/Views/Admin/Couriers"));
const AddCourier = lazy(() => import("../src/Views/Admin/Addcourier"));
const EditCourier = lazy(() => import("../src/Views/Admin/Editcourier"));
const Offer = lazy(() => import("../src/Views/Admin/Offer"));
export const Routes = [
    {
        path:"/",
        component:Home,
        exact:true
    },
    {
        path:"/services",
        component:Services,
        exact:true
    },
    {
        path:"/gallery",
        component:UIGallery,
        exact:true
    },
    {
        path:"/contact_us",
        component:Contact,
        exact:true
    },
    {
        path:"/tracking/:billNo",
        component:Tracking,
        exact:true
    },
    {
        path:"/admin/login",
        component:Login,
        exact:true
    },
    {
        path:"/admin/dashboard",
        component:Dashboard,
        exact:true
    },
    {
        path:"/admin/contact_leads",
        component:ContactLeads,
        exact:true
    },
    {
        path:"/admin/gallery",
        component:Gallery,
        exact:true
    },
    {
        path:"/admin/couriers",
        component:Couriers,
        exact:true
    },
    {
        path:"/admin/add_courier",
        component:AddCourier,
        exact:true
    },
    {
        path:"/admin/edit_courier/:id",
        component:EditCourier,
    },
    {
        path:"/admin/offer",
        component:Offer,
        exact:true
    },
]