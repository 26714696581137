import React from "react";

const Footer = () => {
    return (
        <div className="container-fluid bg-dark text-white mt-5 py-5 px-sm-3 px-md-5">
            <div className="row">
                <div className="col-md-6 mb-5">
                    <h3 className="text-primary mb-4 clr-white">Get In Touch</h3>
                    <p className="clr-white"><i className="fa fa-map mr-2"></i>Faizal complex, Opp al ozami hospital, Abraq Kaithan - Kuwait.</p>
                    <p className="clr-white"><i className="fa fa-phone mr-2"></i>+965 5540 7640 / 6671 3566 / 9778 2015</p>
                    <p className="clr-white"><i className="fa fa-envelope mr-2"></i>Supermaxcargokwi@gmail.com</p>
                </div>
                <div className="col-md-6 mb-5">
                    <h3 className="text-primary mb-4 clr-white">Quick Links</h3>
                    <div className="d-flex flex-column justify-content-start">
                        <a className="text-white mb-2" href="/"><i className="fa fa-angle-right mr-2"></i>Home</a>
                        <a className="text-white mb-2" href="/services"><i className="fa fa-angle-right mr-2"></i>Our
                            Services</a>
                        <a className="text-white mb-2" href="/services"><i className="fa fa-angle-right mr-2"></i>Gallery</a>
                        <a className="text-white" href="/contact_us"><i className="fa fa-angle-right mr-2"></i>Contact Us</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;